<template>
  <v-card outlined class="ma-4">

    <!-- header -->
    <v-toolbar flat color="primary" dense>
      <v-toolbar-title>Zawody</v-toolbar-title>
    </v-toolbar>

    <!-- competitions -->
    <v-simple-table dense
                    :style="[$vuetify.theme.dark ? {'border-bottom': '1px solid #2f2f2f'} : {'border-bottom': '1px solid #e0e0e0'}]">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Zawody</th>
          <th class="text-left">Data rozpoczęcia</th>
          <th class="text-left">Data Zakończenia</th>
          <th class="text-center">Zawodnicy</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="c in competitions" :key="c.id" style="cursor: pointer"
            @click="$router.push('/competitions/' + c.id)">
          <td class="text-left">{{ c.name }}</td>
          <td class="text-left">{{ $utils.dates.parseDate(c.start_date) }}</td>
          <td class="text-left">{{ $utils.dates.parseDate(c.end_date) }}</td>
          <td class="text-center">{{ c.competitors }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-card>
</template>

<script>
export default {
  name: 'Competitions',
  data: () => ({
    search: '', competitions: []
  }),
  created() {
    this.$http.post('/api/competitions').then((res) => {
      this.competitions = res.data;
    }).catch((e) => {
      console.log(e);
    });
  },
  methods: {
    openURL(url) {
      window.open(url, '_blank');
    }
  }
};
</script>
